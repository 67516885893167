<template>
  <div class="relative flex h-full w-full flex-col items-center justify-center">
    <EmbeddedCustomerAppNavigationOgLogo class="w-[40px] py-[40px]" />
    <div
      v-if="showClose"
      class="absolute right-0 top-[24px] flex h-[48px] w-[48px] translate-x-1/2 cursor-pointer items-center justify-center rounded-2xl border-[3px] border-steel bg-[#44456c] text-white"
      @click="uiStore.hideMobileCustomerNav()"
    >
      <BaseIcon icon="customer-app-close" :size="14" />
    </div>
    <nav>
      <EmbeddedCustomerAppNavigationItem
        v-for="item in items.main"
        :key="item.to"
        :item="item"
        class="ml-[-36px]"
        :labels="true"
      />
    </nav>
    <nav class="flex h-full flex-col items-center justify-end">
      <EmbeddedCustomerAppNavigationItem
        v-for="item in items.sub"
        :key="item.to"
        :item="item"
        class="ml-[-36px]"
        :labels="true"
      />
      <EmbeddedCustomerAppNavigationAvatar
        :collapsed="false"
        avatar-size="default"
        class="px-[26px] py-[20px]"
      />
    </nav>
  </div>
</template>
<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const uiStore = useUIStore();

interface Props {
  items: CustomerApp.NavigationItems;
  showClose?: Boolean;
}

defineProps<Props>();
</script>
