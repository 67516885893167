<template>
  <div class="flex min-h-screen w-full flex-col md:flex-row">
    <GodModeBar />
    <EmbeddedCustomerAppNavigation />
    <div class="flex w-full flex-col">
      <PageHeader
        class="mt-[50px] w-full md:mt-0 md:w-[calc(100%-50px)] lg-customer-app:w-[calc(100%-208px)]"
      />
      <NuxtPage class="mt-16" />
      <PageFooter v-if="!isEmbedded" />
      <div data-teleport="compact-navigation" aria-hidden="true" />
      <div data-teleport="request-flow" aria-hidden="true" />
      <div
        data-teleport="customer-app-mobile-navigation-flyout"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from "~/store/UIStore";

const { isEmbedded } = useUIStore();
</script>
